import { boolComparitor, makeOrderedListComparitor, numComparitor, SortStateComparitor } from "../../utils/sort";
import {
  EditionMetaRecordType,
  EntitlementIntegrations,
  EntitlementSupport,
  EntitlementType,
  EntitlementValueTypeMap,
  ReclaimEditionStr,
  SubscriptionFrequencyStr,
} from "./Team.types";

export const EDITION_ORDER: readonly ReclaimEditionStr[] = Object.freeze([
  "NONE",
  "ASSISTANT",
  "LITE",
  "PRO",
  "STARTER",
  "TEAM",
  "BUSINESS",
  "REWARD",
  "ENTERPRISE",
  "TRIAL",
  "LEGACY_PRO_TRIAL",
  "LEGACY_TEAM_TRIAL",
  "TRIAL_BUSINESS",
]);

export const EDITION_META: Readonly<Record<ReclaimEditionStr, EditionMetaRecordType>> = Object.freeze({
  ASSISTANT: { label: "Free", legacy: true },
  LEGACY_PRO_TRIAL: { label: "Pro", isTrial: true, legacy: true },
  LEGACY_TEAM_TRIAL: { label: "Team", isTrial: true, legacy: true },
  NONE: { label: "", legacy: true },
  PRO: { label: "Pro", legacy: true },
  REWARD: { label: "Team", legacy: true },
  TEAM: { label: "Team", legacy: true },
  TRIAL: { label: "Team", isTrial: true, legacy: true },

  TRIAL_BUSINESS: { label: "Business", shortLabel: "biz", isTrial: true, legacy: false },

  LITE: {
    label: "Lite",
    shortLabel: "lte",
    description: "Lite plans are great for those who are new to Reclaim and want to learn the basics for free.",
    legacy: false,
  },
  STARTER: {
    label: "Starter",
    shortLabel: "strtr",
    description: "Starter plans are great for those working alone or on a small team with moderate needs.",
    legacy: false,
  },
  BUSINESS: {
    label: "Business",
    shortLabel: "biz",
    description:
      "Business plans are good for larger teams and companies who want to use Reclaim across the organization.",
    legacy: false,
  },
  ENTERPRISE: {
    label: "Enterprise",
    shortLabel: "ent",
    description: "Enterprise plans are for the largest teams and companies.  Contact us to learn more.",
    legacy: false,
  },
});

export const ENTITLEMENT_META: Readonly<
  {
    [ENTITLEMENT in EntitlementType]: {
      label: string;
      comparitor: SortStateComparitor<EntitlementValueTypeMap[ENTITLEMENT]>;
    };
  }
> = {
  MAX_TEAM_SIZE: {
    label: "Users",
    comparitor: numComparitor,
  },
  SCHEDULER_WEEKS: {
    label: "Scheduler range",
    comparitor: numComparitor,
  },
  MAX_TASKS: {
    label: "Tasks",
    comparitor: numComparitor,
  },
  MAX_CALENDARS: {
    label: "Connected Calendars",
    comparitor: numComparitor,
  },
  MAX_SYNCS: {
    label: "Calendar Syncs",
    comparitor: numComparitor,
  },
  MAX_HABITS: {
    label: "Habits",
    comparitor: numComparitor,
  },
  CUSTOM_BLOCKING: {
    label: "Time blocking",
    comparitor: boolComparitor,
  },
  MAX_SCHEDULING_LINKS: {
    label: "Scheduling Links",
    comparitor: numComparitor,
  },
  MAX_1_ON_1_ORGANIZE: {
    label: "Organize Smart 1:1s",
    comparitor: numComparitor,
  },
  MAX_1_ON_1_ATTEND: {
    label: "Attend Smart 1:1s",
    comparitor: numComparitor,
  },
  INTEGRATIONS: {
    label: "Integrations",
    comparitor: makeOrderedListComparitor<EntitlementIntegrations>(["ESSENTIALS", "PREMIUM"]),
  },
  SUPPORT: {
    label: "Support",
    comparitor: makeOrderedListComparitor<EntitlementSupport>(["BASIC", "COMMUNITY", "PRIORITY"]),
  },
  SSO: {
    label: "SSO/Domain capture",
    comparitor: boolComparitor,
  },
};

export const SUB_FREQUENCY_ORDER: readonly SubscriptionFrequencyStr[] = Object.freeze(["MONTH", "YEAR"]);
export const SUB_FREQUENCY_META: Readonly<Record<SubscriptionFrequencyStr, { label: string }>> = Object.freeze({
  MONTH: { label: "month" },
  YEAR: { label: "year" },
});
